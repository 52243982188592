<template>
  <div class="layout" :class="{ 'iframe': isIframefusion }">
    <aside
      class="sidebar"
      v-if="uiStore.isSidebarVisible"
      :class="{ 'sidebar-visible': uiStore.isSidebarVisible }"
    >
      <SidebarMenu />
    </aside>
    <section class="main">
      <header
        class="header"
        v-if="uiStore.isTopbarVisible"
        :class="{ 'header-visible': uiStore.isTopbarVisible }"
      >
        <TopBar />
      </header>
      <div class="content-wrapper">
        <div class="content">
          <RouterView />
        </div>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useUi } from '@/stores/ui'
import { findRouteManifest } from './manifest'

const route = useRoute()
const uiStore = useUi()

const isIframefusion = computed(() => {
  const routeManifest = findRouteManifest(
    route.meta.parentName as string,
    route.name as string
  )

  return routeManifest?.remote?.module === 'iframefusion'
})
</script>

<style scoped lang="scss">
.layout {
  display: flex;
  flex: 1;
  overflow: hidden;
  height: 100vh;

  .sidebar {
    width: 0;
    background-color: $basic-secondary-surface;
    overflow-y: auto;
    transition: width 0.3s ease;

    &.sidebar-visible {
      @media (min-width: $laptop) {
        width: auto;
      }
    }
  }

  .main {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }

  .header,
  .content {
    padding: $spacing-lg 5rem 0 5rem;

    @media (max-width: $mobile) {
      padding: $mobile-padding-lg $mobile-padding-md;
    }
  }

  .header {
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease;

    &.header-visible {
      height: auto;
      padding: 0 5rem;
      overflow: visible;
    }
  }
  .content-wrapper {
    overflow-y: auto;

    .content {
      flex: 1;
      position: relative;
      overflow-y: auto;

      // Max width for the content
      max-width: 76.5rem;
    }
  }
}

.layout.iframe {
  .content-wrapper {
    overflow-y: auto;

    .content {
      max-width: unset;
      padding: 0;
    }
  }
}
</style>
