import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router'
import { registerGuard, createRoute } from './routeHelpers'

import { defaults } from './routes'
import { getRouteManifests } from '@/manifest'

const getRoutes = () => {
  const routes: Array<RouteRecordRaw> = [...defaults]
  const remoteRoutes = getRouteManifests().map(mfe =>
    createRoute({
      path: mfe.route.path,
      name: mfe.route.name,
      alias: mfe.route.alias,
      meta: {
        ...mfe.route.meta,
        isTopLevelInNavMenu: true
      },
      children: mfe.children.map(mfeChild =>
        createRoute({
          path: `${mfeChild.route.path}/:subPath(.*)?`,
          alias: mfeChild.route.alias,
          name: mfeChild.route.name,
          component: () => import('@/components/MfeLoader.vue'),
          meta: {
            ...mfeChild.route.meta,
            isVisibleInNavMenu: true,
            parentName: mfe.route.name
          }
        })
      )
    })
  )
  return [...routes, ...remoteRoutes]
}

const routes = getRoutes()

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_APP_BASE_URL),
  routes: routes
})

registerGuard(router)

export default router

export const useRoute = () => {
  return router
}
