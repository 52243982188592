import { useRoute } from '@/router'
import { useAuthUser } from '@/stores/authUser'
import { useFeatureFlags } from '@/stores/featureFlags'
import { useUi } from '@/stores/ui'

import { getRouteManifests } from '@/manifest'
import { useI18n } from '@/plugins/i18nPlugin'
import type { VueI18n } from 'vue-i18n'
import {
  acquireAuthenticationToken,
  acquireAuthenticationTokenFromCache,
  logout
} from '@/shared/utils/authUtils'
import { updateDataLayer, trackEvent } from '@/shared/utils/analyticsUtils'

import {
  Amber,
  type GtmEvent,
  type GtmDataLayer
} from '@hms-kontoret/amber.types'

export const createAuthContext = (): Amber.API.V1.AuthContext => {
  const authUser = useAuthUser()

  return {
    getAuthUser: () => authUser.user,
    getAuthUserCompany: () => authUser.company,
    getAuthUserAccount: () => authUser.account,
    getAuthUserAccessItems: () => authUser.accessibleMenuItems,
    getToken: acquireAuthenticationToken,
    getTokenFromCache: acquireAuthenticationTokenFromCache,
    isMenuItemAccessible: (menuItem: string) =>
      authUser.isMenuItemAccessible(menuItem),
    isAnyMenuItemAccessible: (menuItems: string[]) =>
      authUser.isAnyMenuItemAccessible(menuItems),
    logout: logout
  }
}

export const createFeatureFlagContext = (): Amber.API.V1.FeatureFlagContext => {
  const { isFeatureEnabled, isAnyFeatureEnabled } = useFeatureFlags()

  return {
    getFlags: () => useFeatureFlags().featureFlags,
    isFeatureEnabled,
    isAnyFeatureEnabled
  }
}

export const createRouterContext = (): Amber.API.V1.RouterContext => {
  const router = useRoute()

  return {
    navigateTo: (path: string) =>
      window.dispatchEvent(
        new CustomEvent<Amber.Commands.V1.RouteContext.Route.NavigateTo>(
          Amber.Commands.V1.RouteContext.Route.NavigateTo.toString(),
          {
            detail: { path }
          }
        )
      ),
    replaceState: (path: string) =>
      window.dispatchEvent(
        new CustomEvent<Amber.Commands.V1.RouteContext.Route.ReplaceState>(
          Amber.Commands.V1.RouteContext.Route.ReplaceState.toString(),
          {
            detail: { path }
          }
        )
      ),
    getCurrentRoute: () => {
      if (router?.currentRoute) return router.currentRoute.value
      return null
    },
    getRouteManifests: () => getRouteManifests(),
    getBasePath: () =>
      `${window.location.origin}${import.meta.env.VITE_APP_BASE_URL}`,
    getRelativeBasePath: () => import.meta.env.VITE_APP_BASE_URL
  }
}

export const createI18nContext = (): Amber.API.V1.I18nContext => {
  return {
    translate: (key: string, params?: any) => {
      const i18n = useI18n()
      if (!i18n) throw new Error('i18n not initialized')

      const { t } = i18n.global as VueI18n
      return params ? t(key, params) : t(key)
    },
    pluralize: (key: string, choice: number) => {
      const i18n = useI18n()
      if (!i18n) throw new Error('i18n not initialized')

      const { tc } = i18n.global as VueI18n
      return tc(key, choice)
    },
    updateLocale: async (locale: string) => {
      const authUser = useAuthUser()
      if (!authUser.isValidLocale(locale))
        throw new Error(`Invalid locale: ${locale}`)

      await authUser.updateLocale(locale)
    },
    getLocale: () => {
      const i18n = useI18n()
      if (!i18n) throw new Error('i18n not initialized')

      // @ts-ignore - VueI18n does not have a type for locale but it exists
      return i18n.global.locale.value
    }
  }
}

export const createAnalyticsContext = (): Amber.API.V1.AnalyticsContext => {
  return {
    trackEvent: (eventName: GtmEvent, eventTriggerId: string) =>
      trackEvent(eventName, eventTriggerId),
    updateDataLayer: (dataLayer: GtmDataLayer) => updateDataLayer(dataLayer)
  }
}

export const createUiContext = (): Amber.API.V1.UiContext => {
  const uiStore = useUi()
  return {
    enterFullscreen: () => uiStore.enterFullscreen(),
    exitFullscreen: () => uiStore.exitFullscreen(),
    isFullscreen: () => {
      return useUi().isFullscreen
    }
  }
}

export const configureAmberWindowApi = () => {
  const v1AmberAPI = {
    authContext: createAuthContext(),
    featureFlagContext: createFeatureFlagContext(),
    routerContext: createRouterContext(),
    i18nContext: createI18nContext(),
    analyticsContext: createAnalyticsContext(),
    uiContext: createUiContext()
  } as Amber.API.V1.Contexts

  defineWindowAmberAPI({ v1: v1AmberAPI })
}

export const defineWindowAmberAPI = (api: any) => {
  Object.defineProperty(window, 'amberAPI', {
    value: {},
    writable: false,
    configurable: false,
    enumerable: true
  })

  Object.defineProperty(window['amberAPI'], 'v1', {
    value: api.v1,
    writable: false,
    configurable: false,
    enumerable: true
  })
}
