import type { RouteManifest } from '@hms-kontoret/amber.types'
import { MENU_ACCESS } from '@/shared/constants/menuAccess'

const manifest: RouteManifest = {
  name: 'RiskCenter',
  route: {
    name: 'RiskCenter',
    path: '/risk-center',
    alias: ['/risk-management'],
    meta: {
      routeTitle: 'menus__title__risk_management',
      menuIcon: 'n-icon-cone-2',
      isVisibleInNavMenu: true
    }
  },
  children: [
    {
      name: 'RiskCenterMenuLandingPage',
      remote: {
        module: 'menus'
      },
      route: {
        name: 'RiskCenterMenuLandingPage',
        path: '',
        meta: {
          routeTitle: 'menus__title__risk_management',
          routeDescription: 'menu__description__risk_management'
        }
      }
    },
    {
      name: 'RiskAssessmentsIFRAME',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'RiskAssessmentsIFRAME',
        path: 'risikosenter/ros/riskAssessmentsOverview',
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__risk_assessments',
          routeDescription: 'menu__description__risk_assessments',
          isStandaloneRoute: true,
          requiresAccessRights: [MENU_ACCESS.RISK_CENTER],
          menuIcon: 'n-icon-power-level-2'
        }
      }
    },
    {
      name: 'RiskAssessments',
      remote: {
        module: 'risk-assessments'
      },
      route: {
        name: 'RiskAssessments',
        path: 'risk-assessments',
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'Risk Assessments (MFE)',
          routeDescription: 'menu__description__risk_assessments',
          requiresAccessRights: [MENU_ACCESS.RISK_CENTER],
          menuIcon: 'n-icon-power-level-2'
        }
      }
    },
    {
      name: 'JobSafetyAnalysis',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'JobSafetyAnalysis',
        path: 'risikosenter/jsa/jobsafetyanalysis_overview',
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__job_safety_analysis',
          routeDescription: 'menu__description__job_safety_analysis',
          isStandaloneRoute: true,
          requiresAccessRights: [MENU_ACCESS.JOB_SAFETY_ANALYSIS],
          menuIcon: 'n-icon-construction-sign-2'
        }
      }
    },
    {
      name: 'RiskCriteria',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'RiskCriteria',
        path: 'risk-criteria',
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__risk_criteria',
          routeDescription: 'menu__description__risk_criteria',
          requiresAccessRights: [MENU_ACCESS.RISK_CRITERIA],
          menuIcon: 'n-icon-system-preferences-2'
        }
      }
    },

    {
      name: 'RiskAssesmentReports',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'RiskAssesmentReports',
        path: 'risikosenter/ros/riskAssessmentReportsOverview',
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__risk_assessment_reports',
          routeDescription: 'menu__description__risk_assessment_reports',
          isStandaloneRoute: true,
          requiresAccessRights: [MENU_ACCESS.RISK_REPORT],
          menuIcon: 'n-icon-ranking-2'
        }
      }
    }
  ]
}

export default manifest
