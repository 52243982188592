import type { RouteManifest } from '@hms-kontoret/amber.types'
import { MENU_ACCESS } from '@/shared/constants/menuAccess'

const manifest: RouteManifest = {
  name: 'MyCompany',
  route: {
    name: 'MyCompany',
    path: '/company',
    meta: {
      routeTitle: 'menus__title__company',
      menuIcon: 'n-icon-office-2',
      isVisibleInNavMenu: true
    }
  },
  children: [
    {
      name: 'CompanyLandingPage',
      remote: {
        module: 'menus'
      },
      route: {
        name: 'CompanyLandingPage',
        path: '',
        meta: {
          routeTitle: 'menus__title__company',
          routeDescription: 'menu__description__company'
        }
      }
    },
    {
      name: 'Departments',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'Departments',
        path: 'departments',
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__departments',
          routeDescription: 'menu__description__departments',
          menuIcon: 'n-icon-team-2',
          requiresAccessRights: [MENU_ACCESS.MY_COMPANY_INFORMATION_DEPARTMENT]
        }
      }
    },
    {
      name: 'CompanyInfo',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'CompanyInfo',
        path: 'company-info',
        alias: ['companyinfo'],
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__company_info',
          routeDescription: 'menu__description__company_info',
          menuIcon: 'n-icon-c-info',
          requiresAccessRights: [MENU_ACCESS.MY_COMPANY_INFORMATION]
        }
      }
    },
    {
      name: 'Premises',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'Premises',
        path: 'premises',
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__premises',
          routeDescription: 'menu__description__premises',
          menuIcon: 'n-icon-pin-3-2',
          requiresAccessRights: [MENU_ACCESS.MY_COMPANY_INFORMATION_PREMISES]
        }
      }
    },

    {
      name: 'OrganizationalCharts',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'OrganizationalCharts',
        path: 'organizational-charts',
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__organizational_charts',
          routeDescription: 'menu__description__organizational_charts',
          menuIcon: 'n-icon-meeting-2',
          requiresAccessRights: [MENU_ACCESS.MY_COMPANY_INFORMATION_ORGANIZATION_CHART]
        }
      }
    },
    {
      name: 'CompanyGoals',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'CompanyGoals',
        path: 'malsetting/malsettings',
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__goals',
          routeDescription: 'menu__description__goals',
          isStandaloneRoute: true,
          menuIcon: 'n-icon-road-sign-right-2',
          requiresAccessRights: [MENU_ACCESS.MY_COMPANY_INFORMATION_GOALS]
        }
      }
    },
    {
      name: 'ObligationAndAuthority',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'ObligationAndAuthority',
        path: 'ansvarmyndighet/ansvarmyndighet',
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__obligation_and_authority',
          routeDescription: 'menu__description__obligation_and_authority',
          isStandaloneRoute: true,
          menuIcon: 'n-icon-handshake-2',
          requiresAccessRights: [MENU_ACCESS.MY_COMPANY_INFORMATION_OBLIGATION_AND_AUTHORITY]
        }
      }
    },
    {
      name: 'Projects',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'Projects',
        path: 'settings/projects',
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__projects',
          routeDescription: 'menu__description__projects',
          isStandaloneRoute: true,
          requiresAccessRights: [MENU_ACCESS.PROJEKT_ADMIN],
          menuIcon: 'n-icon-folder-3'
        }
      }
    }
  ]
}

export default manifest
