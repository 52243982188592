import type { RouteLocationNormalized, Router } from 'vue-router'
import { msalInstance, loginRequest } from '@/config/authConfig'
import { InteractionType } from '@azure/msal-browser'

import { useAccountService } from '@/services/accountsService'
import { useCustomerProductPackageService } from '@/services/customerProductPackagesService'

import { useAuthUser } from '@/stores/authUser'

import { isAuthenticated } from '@/shared/utils/authUtils'
import { hasRoutePermission } from '@/shared/utils/routeUtils'

import { type UserState, UserType } from '@hms-kontoret/amber.types'
import type { IsAuthenticatedResult } from '@/shared/types'

// Check if the redirect is from the login page
const isLoginRedirect = (to: RouteLocationNormalized): boolean => {
  const loginPath = '/app/login'
  return (
    to.redirectedFrom?.path.startsWith(loginPath) ||
    to.path.startsWith(loginPath)
  )
}

const determineRedirectPath = (
  to: RouteLocationNormalized,
  user: UserState,
  authResult: IsAuthenticatedResult
): string => {
  // Redirect based on user state
  if (user.type === UserType.SUPPORT) return to.fullPath
  if (user.onboardingNeeded) return '/onboarding/onboarding'

  // If the user was not redirected from login and has a previous page state
  if (
    authResult.state &&
    authResult.state !== '/' &&
    authResult.state !== '/index'
  )
    return authResult.state

  // Default to service overview after login
  return '/home'
}

export const authGuard = async (to: RouteLocationNormalized) => {
  try {
    // Skip authentication check if not required
    if (!to.meta.requiresAuth) return true

    // Check authentication and set redirect state to current path
    const authRequest = { ...loginRequest, state: to.fullPath }
    const authResult = await isAuthenticated(
      msalInstance,
      InteractionType.Redirect,
      authRequest
    )

    if (!authResult.authenticated) {
      // Use MSAL to redirect to the login page
      msalInstance.loginRedirect(authRequest)
      return false // Abort the current navigation as MSAL will take over
    }

    // Fetch user data if not already initialized
    const { updateWithInitialData, initialDataFetched } = useAuthUser()
    const { getCustomerProductPackage } = useCustomerProductPackageService()
    if (!initialDataFetched) {
      const initData = await useAccountService().getInitData()
      const customerProductPackage = await getCustomerProductPackage()

      if (initData) updateWithInitialData(initData, customerProductPackage)
    }

    // Handle redirection for login flow
    if (isLoginRedirect(to)) {
      const redirectPath = determineRedirectPath(
        to,
        useAuthUser().user,
        authResult
      )

      if (redirectPath !== to.fullPath) return redirectPath
    }

    // Check route permissions, fallback to 'home' if permission is denied
    if (!hasRoutePermission(to)) return '/home'

    // If all checks pass, allow navigation
    return true
  } catch (error: any) {
    console.error('Error during navigation:', error)

    // Default fallback for unexpected errors
    return false
  }
}

const registerGuard = (router: Router) => {
  router.beforeEach((to: RouteLocationNormalized) => authGuard(to))
}

export default registerGuard
