import { App, defineAsyncComponent } from 'vue'

export function configureRemoteComponents(app: App) {
  // FYI Vite expects static imports
  // MFE Menus
  app.component(
    'TopBar',
    defineAsyncComponent(() => import('menus/TopBar'))
  )
  app.component(
    'SidebarMenu',
    defineAsyncComponent(() => import('menus/SidebarMenu'))
  )
  app.component(
    'HamburgerSidebarMenu',
    defineAsyncComponent(() => import('menus/HamburgerSidebarMenu'))
  )
}
