import type { RouteComponent } from 'vue-router'
import type { RouteOptions } from '@hms-kontoret/amber.types'

/**
 * If the component is a function or a component reference, return it directly. Otherwise, import it dynamically
 * @param component
 * @returns RouteComponent
 */
const resolveComponent = (
  component: RouteComponent | string | undefined
): RouteComponent | undefined => {
  if (component != null) {
    if (typeof component === 'function') return component

    return () => import(`@/views/${component}.vue`)
  }
  return undefined
}

/**
 * @param options RouteOptions
 * @returns RouteRecordRaw, but using any to avoid circular type reference
 */
export const createRoute = (options: RouteOptions): any => ({
  alias: options.alias ?? '',
  path: options.path,
  name: options.name,
  redirect: options.redirect || undefined,
  beforeEnter: options.beforeEnter || undefined,
  component: resolveComponent(options.component),
  meta: {
    requiresAuth: options.meta?.requiresAuth ?? true,
    requiresFeatureFlags: options.meta?.requiresFeatureFlags ?? null,
    requiresAccessRights: options.meta?.requiresAccessRights ?? null,
    routeTitle: options.meta?.routeTitle ?? '',
    routeDescription: options.meta?.routeDescription ?? '',
    isVisibleInNavMenu: options.meta?.isVisibleInNavMenu ?? false,
    isTopLevelInNavMenu: options.meta?.isTopLevelInNavMenu ?? false,
    menuIcon: options.meta?.menuIcon ?? '',
    isStandaloneRoute: options.meta?.isStandaloneRoute ?? false,
    parentName: options.meta?.parentName ?? ''
  },
  children: (options.children?.map(createRoute) as any[]) ?? []
})

export default createRoute
