import {
  LogLevel,
  PublicClientApplication,
  BrowserCacheLocation
} from '@azure/msal-browser'

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    authority: import.meta.env.VITE_AZURE_B2C_AUTHORITY,
    clientId: import.meta.env.VITE_AZURE_B2C_CLIENT_ID,
    redirectUri: import.meta.env.VITE_LOGIN_REDIRECT_URL,
    postLogoutRedirectUri: import.meta.env.VITE_LOGIN_REDIRECT_URL,
    knownAuthorities: [import.meta.env.VITE_AZURE_B2C_KNOWNAUTHORITY]
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage
  },
  system: {
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean
      ) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message)
            return
          case LogLevel.Info:
            console.info(message)
            return
          case LogLevel.Verbose:
            console.debug(message)
            return
          case LogLevel.Warning:
            console.warn(message)
            return
          default:
            return
        }
      },
      logLevel: LogLevel.Warning
    }
  }
}

// Create instance
export const msalInstance = new PublicClientApplication(msalConfig)

// **Ensure initialization completes before export**
export const initMsal = async () => {
  await msalInstance.initialize()
  return msalInstance
}

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ['openid', import.meta.env.VITE_AZURE_B2C_CLIENT_ID]
}

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me'
}
