import { acquireAuthenticationToken } from './utils/authUtils'

const BASE_URL = import.meta.env.VITE_API_URL

/**
 * Determines whether a given URL is a full absolute URL.
 * @param url The URL string.
 * @returns `true` if the URL is absolute, otherwise `false`.
 */
const isAbsoluteUrl = (url: string): boolean => /^https?:\/\//.test(url)

/**
 * Ensures the correct URL by prepending `BASE_URL` only if it's a relative path.
 * @param url The input URL.
 * @returns The full URL.
 */
const resolveUrl = (url: string): string =>
  isAbsoluteUrl(url) ? url : `${BASE_URL}${url}`

/*
 * Retrieves the current user's ID token.
 */
export const getToken = async () => {
  const { idToken } = await acquireAuthenticationToken()
  return idToken
}

/**
 * Creates headers for a fetch request with the current user's ID token.
 * @param token Optional authentication token. If not provided, it will be fetched.
 * @param extraHeaders Additional headers to include in the request.
 * @returns Headers for the fetch request.
 */
export const createHeaders = async (
  token: string | undefined = undefined,
  extraHeaders: Record<string, string> = {}
): Promise<HeadersInit> => {
  if (!token) token = await getToken()

  return {
    Authorization: `Token ${token}`,
    'Content-Type': 'application/json',
    ...extraHeaders // Merge extra headers dynamically
  }
}

/**
 * Handles the response from a fetch request.
 * @param response Response object from the fetch request.
 * @returns Data formatted as JSON, or `undefined` if no content.
 */
export const handleResponse = async <S>(
  response: Response
): Promise<S | undefined> => {
  if (response.ok)
    return response.status === 204 ? undefined : await response.json()
  throw new Error(await response.text())
}

/**
 * Sends a PUT request to the specified endpoint with the given data.
 */
export const createPutRequest = async <S>(
  url: string,
  body: any,
  extraHeaders: Record<string, string> = {}
) => {
  try {
    const request = new Request(resolveUrl(url), {
      headers: await createHeaders(undefined, extraHeaders),
      method: 'PUT',
      body: JSON.stringify(body)
    })

    const response = await fetch(request)
    return await handleResponse<S>(response)
  } catch (error) {
    console.error(error)
    throw error
  }
}

/**
 * Sends a POST request to the specified endpoint with the given data.
 */
export const createPostRequest = async <S>(
  url: string,
  body: any,
  extraHeaders: Record<string, string> = {}
) => {
  try {
    const request = new Request(resolveUrl(url), {
      headers: await createHeaders(undefined, extraHeaders),
      method: 'POST',
      body: JSON.stringify(body)
    })

    const response = await fetch(request)
    return await handleResponse<S>(response)
  } catch (error) {
    console.error(error)
    throw error
  }
}

/**
 * Sends a DELETE request to the specified endpoint.
 */
export const createDeleteRequest = async <S>(
  url: string,
  extraHeaders: Record<string, string> = {}
) => {
  try {
    const request = new Request(resolveUrl(url), {
      headers: await createHeaders(undefined, extraHeaders),
      method: 'DELETE'
    })

    const response = await fetch(request)
    return await handleResponse<S>(response)
  } catch (error) {
    console.error(error)
    throw error
  }
}

/**
 * Sends a GET request to the specified endpoint and returns data formatted as JSON.
 */
export const createGetRequest = async <S>(
  url: string,
  extraHeaders: Record<string, string> = {}
) => {
  try {
    const request = new Request(resolveUrl(url), {
      headers: await createHeaders(undefined, extraHeaders),
      method: 'GET'
    })

    const response = await fetch(request)
    return await handleResponse<S>(response)
  } catch (error) {
    console.error(error)
    throw error
  }
}

/**
 * Sends a GET request to the specified endpoint and returns the response as text.
 */
export const createGetTextRequest = async (
  url: string,
  extraHeaders: Record<string, string> = {}
) => {
  try {
    const request = new Request(resolveUrl(url), {
      headers: await createHeaders(undefined, extraHeaders),
      method: 'GET'
    })

    const response = await fetch(request)
    return await handleResponse<string>(response)
  } catch (error) {
    console.error(error)
    throw error
  }
}
