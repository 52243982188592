import { msalInstance, loginRequest, msalConfig } from '@/config/authConfig'
import {
  InteractionType,
  PublicClientApplication,
  type AuthenticationResult,
  type RedirectRequest,
  type SilentRequest,
  type AccountInfo
} from '@azure/msal-browser'
import { useMsal, CustomMsalEvent } from '@/plugins/msalPlugin'
import { getCookie } from '@/shared/utils/cookieUtils'

import type { Amber } from '@hms-kontoret/amber.types'
import type { IsAuthenticatedResult } from '@/shared/types'

// Token structure stored in sessionStorage
interface CachedToken {
  secret: string
}

/**
 * Checks if the user is authenticated through the proxy or MSAL.
 * @param {PublicClientApplication} instance The MSAL instance to check authentication with.
 * @param {InteractionType} interactionType The type of interaction to use for authentication.
 * @param {RedirectRequest} loginRequest The login request to use for authentication.
 * @returns {Promise<IsAuthenticatedResult>} The result of the authentication check.
 */
export const tryProxyAuthentication = async (): Promise<string | null> => {
  // Early return if hostname doesn't include 'proxy'
  if (!window.location.hostname.includes('proxy')) return null

  const msalToken = useMsal()?.token?.proxy
  if (msalToken) return msalToken

  const cookieToken = getCookie('token')
  if (!cookieToken) return null

  document.dispatchEvent(
    new CustomEvent(CustomMsalEvent.PROXY_LOGIN, { detail: cookieToken })
  )

  return cookieToken
}

export async function isAuthenticated(
  instance: PublicClientApplication,
  interactionType: InteractionType,
  loginRequest: RedirectRequest
): Promise<IsAuthenticatedResult> {
  const isProxyAuthenticated = await tryProxyAuthentication()
  if (isProxyAuthenticated) return { authenticated: true }

  const response = await instance.handleRedirectPromise().catch(() => null)
  const accounts = instance.getAllAccounts()

  if (accounts.length > 0)
    return { authenticated: true, state: response?.state ?? null }

  if (interactionType === InteractionType.Redirect) {
    // Redirect immediately without resolving
    await instance.loginRedirect(loginRequest)
    return { authenticated: false }
  }

  return { authenticated: false }
}

/**
 * Acquires an authentication token from MSAL.
 * If the user is authenticated through the proxy, the token is retrieved from the cookie.
 * If the user is not authenticated, the token is acquired through MSAL.
 * If the user is not authenticated, redirects to the login page.
 * @returns {Promise<Amber.API.V1.AuthToken>} The access token and ID token.
 */
export const acquireAuthenticationToken =
  async (): Promise<Amber.API.V1.AuthToken> => {
    const silentRequest = { ...loginRequest } as SilentRequest
    const proxyAuthResult = await tryProxyAuthentication()
    if (proxyAuthResult) return { accessToken: '', idToken: proxyAuthResult }

    try {
      const response: AuthenticationResult =
        await msalInstance.acquireTokenSilent(silentRequest)
      const { accessToken, idToken } = response
      return { accessToken, idToken }
    } catch (error) {
      console.error('Acquire token failed', error)
      throw error
    }
  }

/**
 * NB: Should not be used if not necessary because it might not be up-to-date.
 * Retrieves the token synchronously from MSAL's cache.
 * @returns {Amber.API.V1.AuthToken | null} The access token if found, otherwise null.
 */
export const acquireAuthenticationTokenFromCache =
  (): Amber.API.V1.AuthToken | null => {
    const account: AccountInfo | null = msalInstance.getActiveAccount()
    if (!account) {
      console.warn('No active account found in MSAL cache.')
      return null
    }

    // Retrieve stored token keys
    const msalCacheTokenKeys: string | null = sessionStorage.getItem(
      `msal.token.keys.${msalConfig.auth.clientId}`
    )

    if (!msalCacheTokenKeys) {
      console.warn('No token keys found in sessionStorage.')
      return null
    }

    let parsedKeys: { idToken: string; accessToken: string }
    try {
      parsedKeys = JSON.parse(msalCacheTokenKeys) as {
        idToken: string
        accessToken: string
      }
    } catch (error) {
      console.error('Error parsing token keys from sessionStorage:', error)
      return null
    }

    const { idToken, accessToken } = parsedKeys ?? {}

    if (!idToken || !accessToken) {
      console.warn('No valid ID or access token keys found in sessionStorage.')
      return null
    }

    // Retrieve tokens from sessionStorage
    const idTokenFromCache: string | null = sessionStorage.getItem(idToken)
    const accessTokenFromCache: string | null =
      sessionStorage.getItem(accessToken)

    if (!idTokenFromCache || !accessTokenFromCache) {
      console.warn('Access token or ID token not found in sessionStorage.')
      return null
    }

    let parsedIdToken: CachedToken
    let parsedAccessToken: CachedToken

    try {
      parsedIdToken = JSON.parse(idTokenFromCache) as CachedToken
      parsedAccessToken = JSON.parse(accessTokenFromCache) as CachedToken
    } catch (error) {
      console.error('Error parsing tokens from sessionStorage:', error)
      return null
    }

    return {
      accessToken: parsedAccessToken?.secret ?? '',
      idToken: parsedIdToken?.secret ?? ''
    }
  }

export const logout = async () => {
  document.dispatchEvent(new CustomEvent(CustomMsalEvent.LOGOUT))
}
