import type { RouteManifest } from '@hms-kontoret/amber.types'
import { MENU_ACCESS } from '@/shared/constants/menuAccess'

const manifest: RouteManifest = {
  name: 'CardFile',
  route: {
    name: 'CardFile',
    path: '/card-file',
    meta: {
      routeTitle: 'menus__title__card_file',
      menuIcon: 'n-icon-folder-3',
      isVisibleInNavMenu: true
    }
  },
  children: [
    {
      name: 'CardFileMenuLandingPage',
      remote: {
        module: 'menus'
      },
      route: {
        name: 'CardFileMenuLandingPage',
        path: '',
        meta: {
          routeTitle: 'menus__title__card_file',
          routeDescription: 'menu__description__card_file'
        }
      }
    },
    {
      name: 'DangerousSubstances',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'DangerousSubstances',
        path: 'dangeroussubstances',
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__dangerous_substances',
          routeDescription: 'menu__description__dangerous_substances',
          externalLink: 'https://chemicals.avonova.com',
          requiresAccessRights: [MENU_ACCESS.DANGEROUS_SUBSTANCES],
          menuIcon: 'n-icon-chemistry'
        }
      }
    },
    {
      name: 'WorkEquipment',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'WorkEquipment',
        path: 'arbeidsutstyr/arbeidsutstyr',
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__work_equipment',
          routeDescription: 'menu__description__work_equipment',
          isStandaloneRoute: true,
          requiresAccessRights: [MENU_ACCESS.WORK_EQUIPMENT],
          menuIcon: 'n-icon-ladder-2'
        }
      }
    },
    {
      name: 'Agreements',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'Agreements',
        path: 'agreements',
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__agreements',
          routeDescription: 'menu__description__agreements',
          requiresAccessRights: [MENU_ACCESS.AGREEMENTS],
          menuIcon: 'n-icon-handshake-2'
        }
      }
    },
    {
      name: 'LawsAndRegulations',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'LawsAndRegulations',
        path: 'laws',
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__laws_and_regulations',
          routeDescription: 'menu__description__laws_and_regulations',
          requiresAccessRights: [MENU_ACCESS.LAWS],
          menuIcon: 'n-icon-books-2'
        }
      }
    },
    {
      name: 'Files',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'Files',
        path: 'files',
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__files',
          routeDescription: 'menu__description__files',
          requiresAccessRights: [MENU_ACCESS.FILES],
          menuIcon: 'n-icon-file-pdf-2'
        }
      }
    },
    {
      name: 'Contacts',
      remote: {
        module: 'iframefusion'
      },
      route: {
        name: 'Contacts',
        path: 'contacts',
        meta: {
          isVisibleInNavMenu: true,
          routeTitle: 'menu__title__contacts',
          routeDescription: 'menu__description__contacts',
          requiresAccessRights: [MENU_ACCESS.CONTACTS],
          menuIcon: 'n-icon-user-list-2'
        }
      }
    }
  ]
}

export default manifest
