import { createGetRequest, getToken } from '@/shared/requestHandler'
import { CustomerProductPackage } from './dto'

export const useCustomerProductPackageService = () => {
  const BASE_URL = import.meta.env
    .VITE_ADP_CUSTOMER_PRODUCT_PACKAGE_API_BASE_URL
  const subscriptionKey = import.meta.env
    .VITE_ADP_CUSTOMER_PRODUCT_PACKAGE_API_SUBSCRIPTION_KEY

  /**
   * Fetches customer product package.
   *
   * @async
   * @function getCustomerProductPackage
   * @returns {Promise<CustomerProductPackage  | null>} The promise object representing the customer product packages.
   * @description This function fetches customer product package. If the request fails, it returns `null`.
   */
  const getCustomerProductPackage =
    async (): Promise<CustomerProductPackage | null> => {
      try {
        const url = `${BASE_URL}/customer-product-package`
        return (
          (await createGetRequest<CustomerProductPackage>(url, {
            'Adp-Customer-Product-Package-Api-Subscription-Key':
              subscriptionKey,
            'Authorization': `Bearer ${await getToken()}`
          })) ?? null
        )
      } catch (error) {
        console.error('Error fetching customer product package:', error)
        return null
      }
    }

  return {
    getCustomerProductPackage
  }
}
